body {
    background-color: rgb(32, 59, 68);
}

.title {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 50vh;
    margin-top: 70vh;
    font-size: 40px;
}

.selector1 {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 50vh;
    margin-top: 50vh;
}

.selector2 {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 30vh;
    margin-top: 50vh;
}

.selector3 {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 70vh;
    margin-top: 50vh;
}

.button {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 50vh;
    margin-top: 30vh;
}

.text {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 50vh;
    margin-top: 70vh;
}

.didyouknow {
    border: none;
    color: rgba(255, 255, 255, 0.689);
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    position: absolute;
    left: 50vh;
    margin-top: 70vh;
}